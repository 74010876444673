import { useEffect, useState, createElement as rc, useCallback, Fragment } from 'react';
import { setupRulesEngine } from 'lib_ui-core';
import startCase from 'lodash/startCase';
import AllTenants from './AllTenants';
import Haven from './Haven';

import {
    Pressable,
    nativeOnlyStyles,
    fromTheme,
    Caret,
    Card,
    h1,
    h2,
    View,
    Button,
    styled,
    webOnlyStyles,
    ScrollView,
    hooks,
    WaitScreen
} from 'lib_ui-primitives';
import Collapse from '../helpers/Collapse';
import useEventSink from '../../hooks/useEventSink';
import useUserContext from '../../hooks/useUserContext';
import useToggle from '../../hooks/useToggle';
import { constants } from 'lib_ui-services';
const { useCaseIds, WELL_KNOWN_ROLE_IDS } = constants;
const { useRouter, useFeatureFlags } = hooks;
// eslint-disable-next-line no-undef
const isNative = () => __SST_REACT_NATIVE__ || __TEST_RFID__;

let EnvTitle = styled(Pressable).attrs({ name: 'tenant-title' })`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
EnvTitle = nativeOnlyStyles(EnvTitle)`
    margin-top: ${fromTheme('viewMargin')};
    margin-bottom: ${fromTheme('viewMargin')};
`;

EnvTitle.displayName = 'TenantTitle';
let Centered = styled(View).attrs({ name: 'centered' })`
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
`;

Centered = webOnlyStyles(Centered)`
    box-sizing: border-box;
`;

const Main = styled(ScrollView).attrs({
    name: 'main',
    contentContainerStyle: { justifyContent: 'center', flexGrow: 1 }
})``;

let FullScreenBody = styled(Card.Body)`
    ${({ theme }) =>
        !theme.mobile &&
        !theme.tablet &&
        `
    margin-left: auto;
    margin-right: auto;
    min-width: 800px;
    `};
`;

FullScreenBody = webOnlyStyles(FullScreenBody)`
    overflow-y: auto;
    ${({ theme }) => theme.mobile && 'padding-inline-start: 0;'};
`;

const Header = styled(h1)`
    font-size: 24px;
`;

const ToggleCaret = styled(Caret).attrs({ name: 'toggle-caret' })`
    margin-right: 6px;
    height: 8px;
    width: 8px;
`;
ToggleCaret.displayName = 'ToggleCaret';

const EMPTY_ARRAY = [];
const EMPTY_PROFILE = {};
const _p = { useRouter };
export const _private = _p;

const TenantSelection = () => {
    const featureFlags = useFeatureFlags(true);
    /** @type {import('../../../../types').StateHook<import('../../../../types').UseCase>} */
    const [selectedUseCase, setSelectedUseCase] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [isEnvCollapsed, setIsEnvCollapsed] = useToggle(false);
    const eventSink = useEventSink();
    const [subscribe, publish] = eventSink;
    const profile = useUserContext() || EMPTY_PROFILE;
    const { user, userName, password } = profile;

    const { environment } = _p.useRouter();

    useEffect(() => {
        setupRulesEngine(profile, eventSink);
    }, [eventSink, profile]);

    useEffect(() => {
        // This avoids a server side lookup of the use cases.
        return subscribe(
            { verb: 'get', namespace: 'application', relation: 'predefinedRecords' },
            (payload, context) => {
                const useCases = Object.entries(useCaseIds).map(([key, value]) => ({
                    _id: value,
                    title: startCase(key.toLocaleLowerCase())
                }));
                publish({ records: useCases }, { ...context, status: 'success' });
            }
        );
    }, [subscribe, publish]);

    // if the user only has one tenant and useCase go ahead and select that useCase.
    useEffect(() => {
        if (user?.tenant.length === 1 && user.tenant[0].useCase.length === 1) {
            const tenantId = user.tenant[0]['identity:tenant']._id;
            const useCaseId = user.tenant[0].useCase[0]['metaui:useCase']._id;
            publish(
                { userName, password, tenantId, useCaseId },
                { verb: 'update', namespace: 'security', relation: 'profile', type: 'selectTenant' }
            );
        }
    }, [password, profile, publish, user?.tenant, userName]);

    /**
     * @param {React.MouseEvent<HTMLButtonElement>} event
     */
    // eslint-disable-next-line no-unused-vars
    const onCancel = useCallback(() => {
        publish({ profile }, { verb: 'remove', namespace: 'security', relation: 'profile' });
    }, [profile, publish]);

    /**
     * @params {React.MouseEvent} event
     * @param {import('../../../../types').UseCase} [useCaseFromDoubleClick]
     */
    const onSubmit = useCallback(
        (event, useCaseFromDoubleClick) => {
            const useCase = useCaseFromDoubleClick ?? selectedUseCase;
            if (!useCase) return;
            setSubmitted(true);

            const { user } = profile;
            const tenant = user.tenant.find(
                t =>
                    t['identity:tenant']._id === useCase?.['identity:tenant']._id &&
                    t.useCase.find(uc => uc['metaui:useCase']._id === useCase?.['metaui:useCase']._id)
            );

            publish(
                {
                    ...profile,
                    tenantId: tenant['identity:tenant']._id,
                    useCaseId: useCase['metaui:useCase']._id,
                    type: 'selectTenant'
                },
                { verb: 'update', namespace: 'security', relation: 'profile', type: 'selectTenant' }
            );
        },
        [profile, publish, selectedUseCase]
    );
    const showHavenCreation =
        !isNative() &&
        ['local', 'dev'].includes(environment) &&
        hasSupportRoleInOneTouch(profile) &&
        featureFlags.includes('haven');

    // prettier-ignore
    return rc(WaitScreen, { waiting: submitted, showSpinner: true },
        rc(Centered, null,
            rc(Main, { 'data-testid': 'tenantSelect-content' },
                rc(Card, null,
                    rc(Card.Header, { style: { justifyContent: 'center' } },
                        rc(Header, { name: 'card-header-title', style: { margin: 0 } }, 'Select an Application')
                    ),
                    rc(FullScreenBody, null,
                        showHavenCreation && rc(Fragment, null,
                            rc(EnvTitle, { role: 'switch', onClick: () => setIsEnvCollapsed(x => !x)},
                                rc(ToggleCaret, {  isOpen: !isEnvCollapsed }),
                                rc(h2, null, startCase(environment))
                            ),
                            rc(Collapse, { isCollapsed: isEnvCollapsed },
                                rc(AllTenants, {tenants: (user?.tenant ?? EMPTY_ARRAY), onSubmit, selectedUseCase, setSelectedUseCase})
                            ),
                            rc(Haven)
                        ),
                        !showHavenCreation && rc(AllTenants, {tenants: (user?.tenant ?? EMPTY_ARRAY), onSubmit, selectedUseCase, setSelectedUseCase})
                    ),
                    rc(Card.Footer, { style: { justifyContent: 'flex-end' } },
                        rc(Button, { onClick: onCancel, value: 'CANCEL', buttonStyle: 'primary', disabled: submitted }),
                        rc(Button, { onClick: onSubmit, value: 'OK', disabled: !selectedUseCase || submitted, buttonStyle: 'success' })
                    )
                )
            )
        )
    );
};

export default TenantSelection;

// TODO: Create unit tests for this as part of havens feature
function hasSupportRoleInOneTouch(profile) {
    return profile?.user?.tenant?.some(t =>
        t.useCase.some(
            uc =>
                uc['metaui:useCase']._id === useCaseIds.ONE_TOUCH &&
                uc['identity:role']._id === WELL_KNOWN_ROLE_IDS.SUPPORT
        )
    );
}
