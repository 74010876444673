import logging from '@sstdev/lib_logging';
import io from 'socket.io-client';
import getGlobalConfig from '../globalConfig/index';
import { getCurrentToken } from '../authentication/cache';
import handleSocketEvents from './socketEventHandlers';
const socketEndpointLastNode = getGlobalConfig().socketEndpointLastNode;

// encapsulate this stuff to make it easier to refactor this module into smaller pieces
const socketState = {
    socket: undefined,
    eventSink: undefined,
    offline: false,
    initialStartup: true,
    isOnline: false,
    updateSocketStatus: isOnline => {
        if (isOnline !== socketState.isOnline) {
            socketState.isOnline = isOnline;
            const [, publish] = socketState.eventSink;
            publish(
                {
                    isOnline
                },
                { verb: 'update', namespace: 'application', relation: 'socket' }
            );
        }
    }
};

export async function connect(eventSink) {
    socketState.eventSink = socketState.eventSink ?? eventSink;
    if (socketState.socket) return;

    let token = await getCurrentToken();
    logging.debug('[SOCKETS] Establishing socket connection.');

    if (token.startsWith('Bearer ')) {
        token = token.replace('Bearer ', '');
    }
    
    let socketConfig = {
        transports: ['websocket'],
        path: `/g/${getGlobalConfig().groupNumber}/${socketEndpointLastNode}`,
        auth: { token: `Bearer ${token}` }
    };
    let uri = getGlobalConfig().host;
    // eslint-disable-next-line no-undef
    if (__SST_REACT_NATIVE__) {
        // This version of React Native is not spec compliant on handling
        // XMLHttpRequests - so skip trying to long-poll
        // see https://github.com/facebook/react-native/issues/13991
        socketConfig = {
            transports: ['websocket'],
            pingTimeout: 30000,
            pingInterval: 30000,
            path: `/g/${getGlobalConfig().groupNumber}/reactnative/${socketEndpointLastNode}`,
            auth: { token: `Bearer ${token}` }
        };
        uri = `${getGlobalConfig().protocolSocket}//${getGlobalConfig().host}`;
    }

    socketState.socket = io(uri, socketConfig); // undefined will cause to use the current page's web host (which is not available on react native)

    handleSocketEvents(socketState, token);

    // Browser devtools does not allow setting sockets offline
    // to test offline you have to do it in the console
    // see \lib_ui-core\src\devToolCommands.js for more details
}

export const getStatus = () => {
    return socketState.isOnline;
};

export function setOffline() {
    socketState.offline = true;
    if (socketState.socket) socketState.socket.close();
}
export function setOnline() {
    socketState.offline = false;
    if (socketState.socket == null) {
        connect().then(() => {
            socketState.socket.connect();
        });
    } else {
        socketState.socket.connect();
    }
}

export function reconnect() {
    setOffline();
    setOnline();
}

export function disconnect() {
    if (socketState.socket) socketState.socket.close();
    socketState.socket = null;
}
