import log from '@sstdev/lib_logging';
import { default as globalConfig } from '../globalConfig/index';
import { default as Database } from './Database';

let database;
const readiness = {
    setCrashed: undefined,
    setReady: undefined,
    isDbReady: undefined
};
readiness.isDbReady = new Promise((resolve, reject) => {
    readiness.setReady = resolve;
    readiness.setCrashed = reject;
});
export const isDbReady = () => readiness.isDbReady;
const _p = {
    initialize,
    readiness,
    database
};
export default {
    get: getDatabase,
    initialize: InitializeForUser,
    isDbReady,
    _private: _p
};

function getDatabase() {
    return _p.database;
}
export async function InitializeForUser(user, eventSink, useCase) {
    log.debug('[PERSISTENCE] Initializing for user');
    const [, publish] = eventSink;

    await initialize(
        {
            config: globalConfig(),
            namespaces: useCase.namespaces,
            user,
            tenantId: user.activeTenant['identity:tenant']._id,
            useCaseId: user.activeUseCase['metaui:useCase']._id,
            groupUrlPrefix: user.groupName
        },
        publish
    );
    readiness.setReady();
}

async function initialize(payload, actionPublisher) {
    const { config, namespaces, tenantId, useCaseId } = payload;

    const settings = {
        config,
        namespaces,
        tenantId,
        useCaseId,
        actionPublisher
    };
    _p.database = new Database(settings);

    await _p.database.initializeStorage();
    return _p.database.dispatch([], payload, {
        verb: 'persist',
        namespace: 'application',
        relation: 'useCase',
        type: 'initialize'
    });
}
