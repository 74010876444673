import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { memoize } = lodash;
import http from './http';
import getFullURL from './http/getFullURL';

const ISSERVERREACHABLE_CACHE_LIFETIME = 1000;
const getIsServerReachable = memoize(_getIsServerReachable);
let socketEstablishedAtLeastOnce = false;
let socketOnline = false;
export async function getStatus() {
    const browserNetworkInfo = getBrowserNetworkInfo();
    // Don't waste time on a netowrk call for isServerReachable if the browser says the
    // network isn't available.
    if (!browserNetworkInfo.isConnected) {
        return {
            /**
             * indicates if connected and server is reachable
             * @type {boolean}
             */
            isOnline: false,
            // indicates if the server is reachable
            isServerReachable: false,
            ...browserNetworkInfo
        };
    }
    // Double check that the http server is available (but only if sockets are online or
    // completely unavailable).
    const isServerReachable = (socketEstablishedAtLeastOnce && socketOnline) || (await getIsServerReachable());
    const isOnline = browserNetworkInfo.isConnected && isServerReachable;
    return {
        /**
         * indicates if connected and server is reachable
         * @type {boolean}
         */
        isOnline,
        // indicates if the server is reachable
        isServerReachable,
        ...browserNetworkInfo
    };
}

function getBrowserNetworkInfo() {
    // If inside a webworker (for instance)
    const navigator = typeof window !== 'undefined' ? window.navigator : global.navigator;

    // `connection` is not always available. Most notably it is not on any sort of Safari.
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
    const networkInfo = navigator.connection;

    const type = networkInfo?.type || 'unknown';

    const effectiveType = networkInfo?.effectiveType ?? 'unknown';
    return {
        /**
         * Being connected does NOT guarantee being able to reach our server
         * @type {boolean}
         */
        isConnected: navigator.onLine,
        /**
         * The type of connection a device is using to communicate with the network
         * @type {'bluetooth'|'cellular'|'ethernet'|'none'|'wifi'|'wimax'|'other'|'unknown'}
         */
        type,
        /**
         * The effective type/speed of the connection determined by recently observed round-trip time and downlink values
         * @type {'2g' | '3g' | '4g' | 'slow-2g' | undefined}
         * */
        effectiveType
    };
}

export function listenForChange(eventSink) {
    const [subscribe, publish] = eventSink;
    if (typeof window !== 'undefined' && window.addEventListener) {
        logging.info('[NETWORK] Adding Observers');

        const publishNetworkChange = async serverReachable => {
            let networkStatus;
            if (typeof serverReachable !== 'boolean') {
                networkStatus = await getStatus();
            } else {
                networkStatus = getBrowserNetworkInfo();
                networkStatus.isServerReachable = serverReachable;
                networkStatus.isOnline = networkStatus.isConnected && serverReachable;
            }
            logging.info(`[NETWORK] Connection ${networkStatus.isOnline ? 'restored' : 'lost'}`);
            return publish(networkStatus, { verb: 'update', namespace: 'application', relation: 'network' });
        };
        window.addEventListener('online', publishNetworkChange);
        window.addEventListener('offline', publishNetworkChange);
        // Socket has an efficient polling mechanism. If the socket says network has
        // changed, publish it.
        const unsubscribeSocketStatus = subscribe(
            { verb: 'update', namespace: 'application', relation: 'socket' },
            ({ isOnline }) => {
                // Don't publish a network change the first time sockets connect.
                if (isOnline && !socketEstablishedAtLeastOnce) {
                    socketEstablishedAtLeastOnce = true;
                    socketOnline = isOnline;
                } else if (socketOnline !== isOnline) {
                    socketOnline = isOnline;
                    // getStatus will double check whether server is available using http HEAD call.
                    publishNetworkChange(isOnline);
                }
            }
        );

        let unsubscribes = [
            () => window.removeEventListener('online', publishNetworkChange),
            () => window.removeEventListener('offline', publishNetworkChange),
            unsubscribeSocketStatus
        ];

        return () => {
            logging.info('[NETWORK] Closing Observers');
            return unsubscribes.forEach(u => u());
        };
    }
    // otherwise return a noop for the cancel method.
    return () => {};
}

export default { getStatus, listenForChange, clearCache };

async function _getIsServerReachable() {
    // avoid CORS errors with a request to your own origin
    const url = new URL(getFullURL('/healthcheck'));

    // random value to prevent cached responses
    url.searchParams.set('rand', getRandomCacheBusterString());

    try {
        const response = await fetch(url.toString(), { method: 'HEAD' });

        return response.ok;
    } catch {
        return false;
    } finally {
        setTimeout(() => {
            getIsServerReachable?.cache.clear();
        }, ISSERVERREACHABLE_CACHE_LIFETIME);
    }
}
export async function clearCache() {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
        logging.warn('[CACHE] clearCache called in an environment without window or navigator');
        return;
    }
    try {
        // Remove all service workers for the domain
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
            await registration.unregister();
        }

        // Remove all indexedDB databases for the domain
        const dbs = await indexedDB.databases();
        for (const db of dbs) {
            indexedDB.deleteDatabase(db.name);
        }

        // Server will return a 'Clear-Site-Data' header to the browser with the intent of clearing
        // the HTTP cache.  This has uneven support in browsers right now.
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Clear-Site-Data
        await http.get('/clearCache');

        // Redirect to the login page
        window.location.href = '/login';
    } catch (error) {
        logging.error(error);
        throw new Error('Failed to clear cache');
    }
}
function getRandomCacheBusterString() {
    // Good enough for this purpose
    return Math.random().toString(36).substring(2, 15);
}
