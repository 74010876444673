import { createElement as rc, Fragment, useRef, useCallback, useEffect, useState } from 'react';

import { Text, Card, Link, hooks } from 'lib_ui-primitives';
import { nativeOnlyProperties } from 'lib_ui-services';

import Disclaimer from './Disclaimer';
import PasswordWithConfirm from '../formElement/PasswordWithConfirm';
import Email from '../formElement/Email';
import Form from '../form/Form';
import FormButton from '../formButton/FormButton';
import useEventSink from '../../hooks/useEventSink';
import useActiveRecord from '../../hooks/useActiveRecord';
import useAuthenticationType from './useAuthenticationType';
import { CardBox, HeaderText } from './styles';

const { useRouter } = hooks;

let hNode = {
    id: 'confirmForm',
    title: '',
    hNodeType: 'Form',
    hNodeTypeGroup: 'form'
};

const _p = { useAuthenticationType };
export const _private = _p;

/**
 * Both for new users, and for users who used forgot Password and clicked the link in the email
 */
export default function NewUserConfirmation() {
    const errors = _p.useAuthenticationType();

    const [, publish] = useEventSink();
    const activeRecord = useActiveRecord();
    const { namespace, relation, record, type } = activeRecord;

    const { groupNumber } = useRouter();
    let groupPath = '';
    if (groupNumber != undefined && groupNumber !== '') {
        groupPath = `/g/${groupNumber}`;
    }

    const emailRef = useRef();
    const passRef = useRef();
    const onKeyPress = useCallback(
        e => {
            if (e.key === 'Enter') {
                if (emailRef.current.value == null || emailRef.current.value.length === 0) {
                    emailRef.current.focus();
                } else if (passRef.current.value == null || passRef.current.value.length === 0) {
                    passRef.current.focus();
                } else {
                    publish({ record }, { verb: 'submit', namespace, relation, type });
                }
            }
        },
        [namespace, relation, publish, record, type]
    );

    const [showResetPasswordLink, setShowResetPasswordLink] = useState(false);
    useEffect(() => {
        if (errors?.form?.some(error => (error.message || error)?.toLowerCase()?.includes('forgot password'))) {
            setShowResetPasswordLink(true);
        } else {
            setShowResetPasswordLink(false);
        }
    }, [errors]);
    // prettier-ignore
    return rc(Fragment, null,
        rc(CardBox, null,
            rc(Card.Header,
                { style: { justifyContent: 'center' } },
                rc(HeaderText, { name: 'card-header-title', style: { margin: 0 } }, 'Set Password')
            ),
            rc(Card.Body, null,
                rc(Form, { hNode, errors, popErrorNotifications: false, heightGreedy: false, centerFields: true },
                    rc(Email, {
                        ref: emailRef,
                        onKeyPress,
                        ...nativeOnlyProperties({
                            onChange: text => emailRef.current.value = text
                        }),
                        hNode: {
                            id: 'confirmEmail',
                            title: 'Email',
                            hNodeType: 'ShortText',
                            hNodeTypeGroup: 'formElement',
                            placeholder: 'Email',
                            propertyName: 'userName',
                            defaultValue: '',
                            treePosition: { sequence: 1 }
                        }
                    }),
                    rc(PasswordWithConfirm, {
                        ref: passRef,
                        onKeyPress,
                        ...nativeOnlyProperties({
                            onChange: text => passRef.current.value = text
                        }),
                        allowPasswordFill: true,
                        hNode: {
                            id: 'confirm-password',
                            title: 'Password',
                            hNodeType: 'PasswordWithConfirm',
                            hNodeTypeGroup: 'formElement',
                            propertyName: 'password',
                            defaultValue: '',
                            treePosition: { sequence: 2, minSequence: 3, maxSequence: 5 }
                        }
                    }),
                    rc(FormButton, {
                        hNode: {
                            id: 'login',
                            title: 'CONFIRM',
                            hNodeType: 'FormButton',
                            hNodeBlockGroup: 'formButtons',
                            hNodeTypeGroup: 'formButton',
                            formAction: 'submit'
                        }
                    })
                )
            ),
            rc(Card.Footer, { style: { justifyContent: 'space-between', flexShrink: 0, borderTopWidth: 0 } },
                // eslint-disable-next-line no-undef
                !__SST_REACT_NATIVE__ && rc(Link, { id: 'login-link', to: `${groupPath}/login` }, rc(Text, { id: 'login-text' }, 'Login')),
                // eslint-disable-next-line no-undef
                !__SST_REACT_NATIVE__ && showResetPasswordLink && rc(Link, { id: 'reset-link', to: `${groupPath}/reset` }, rc(Text, { id: 'forgot-password-text' }, 'Forgot Password?'))
            )
        ),
        rc(Disclaimer)
    );
}
